<template>
  <div class="box">
    <el-row>
      <el-col :span="4">
        <div class="tree" v-if="data.length>0">
          <draggable v-model="data" :options="bottomNavOptions" group="group1">
            <div v-for="(item,index) in data" :key="index" class="item" @click="editItem(index)">
              <div class="title" :class="{'active':editIdx===index && editChildIdx===-1}">
                <div>
                  <i class="el-icon-caret-bottom"></i>
                  <span :class="{'c999': !item.title}">{{item.title || '请点击编辑'}}</span>
                </div>
                <div class="opt">
                  <el-popconfirm
                      title="确认删除吗？"
                      @confirm="delItem(index)">
                    <i class="el-icon-delete" slot="reference" @click.stop="{}"></i>
                  </el-popconfirm>
                  <i class="el-icon-circle-plus-outline" @click="addChild($event,index)"></i>
                </div>
              </div>
              <draggable v-model="item.children" :options="bottomNavOptions" :group="'group2' + index">
                <div class="li" v-for="(child,cindex) in item.children" :key="cindex" @click="editChild($event,index,cindex)" :class="{'active':editIdx===index && editChildIdx===cindex}">
                  <span :class="{'c999': !child.title}">{{child.title || '请点击编辑'}}</span>
                  <div class="opt">
                    <el-popconfirm
                        title="确认删除吗？"
                        @confirm="delChild(index,cindex)">
                      <i class="el-icon-delete" slot="reference" @click.stop="{}"></i>
                    </el-popconfirm>
                  </div>
                </div>
              </draggable>
            </div>
          </draggable>
        </div>
        <div class="c666 mg-tp-20" v-else>暂无数据</div>
      </el-col>
      <el-col :span="20">
        <div class="form" v-if="editForm">
          <el-tabs v-model="tabName">
            <el-tab-pane label="菜单设置" name="first"></el-tab-pane>
          </el-tabs>
          <el-form :model="editForm" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small" style="width: 50%">
            <el-form-item label="菜单名称" prop="title">
              <el-input v-model="editForm.title" placeholder="请输入菜单名称"></el-input>
            </el-form-item>
            <el-form-item label="菜单类型" prop="menu_type">
              <el-radio-group v-model="editForm.menu_type" size="small">
                <el-radio-button label="1" border>关键词</el-radio-button>
                <el-radio-button label="2" border>网页</el-radio-button>
                <el-radio-button label="3" border>功能页面</el-radio-button>
                <el-radio-button label="4" border>小程序</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="关键词" prop="content.keywords" v-if="editForm.menu_type==1">
              <el-input v-model="editForm.content.keywords" placeholder="请输入关键词"></el-input>
            </el-form-item>
            <el-form-item label="跳转网页" prop="content.url" v-if="editForm.menu_type==2">
              <el-input v-model="editForm.content.url" placeholder="请输入网址"></el-input>
            </el-form-item>
            <el-form-item label="选择功能" prop="content.nav_link" v-if="editForm.menu_type==3">
              <select-link :setData="editForm.content.nav_link || {}" :setFunc="setNavFunc">
                <template slot="button">
                  <el-input :value="editForm.content.nav_link ? editForm.content.nav_link.title : ''" size="small" placeholder="点击设置链接" suffix-icon="el-icon-arrow-right" readonly="readonly"></el-input>
                </template>
              </select-link>
            </el-form-item>
            <el-form-item label="小程序appid" prop="content.appid" v-if="editForm.menu_type==4">
              <el-input v-model="editForm.content.appid" placeholder="请输入appid"></el-input>
            </el-form-item>
            <el-form-item label="小程序路径" prop="content.path" v-if="editForm.menu_type==4">
              <el-input v-model="editForm.content.path" placeholder="请输入路径"></el-input>
            </el-form-item>
            <el-form-item label="备用网址" prop="content.website" v-if="editForm.menu_type==4">
              <el-input v-model="editForm.content.website" placeholder="请输入网址"></el-input>
            </el-form-item>
            <el-form-item>
              <el-popconfirm
                  class="mg-rt-10"
                  title="确认删除吗？"
                  @confirm="delNow()">
                <el-button type="danger" slot="reference">删除</el-button>
              </el-popconfirm>
              <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <div class="mg-tp-30">
      <el-button type="primary" size="small" @click="addItem()">新增菜单</el-button>
      <el-button type="warning" size="small" @click="saveMenu(false)">保存菜单</el-button>
      <el-button type="danger" size="small" @click="saveMenu(true)">发布菜单</el-button>
    </div>
  </div>
</template>
<script>

import draggable from 'vuedraggable';
import selectLink from '@/view/module/select.link.vue'
  export default {
    data() {
      return {
        tabName: 'first',
        data: [],
        defaultProps: {
          children: 'children',
          label: 'title'
        },
        bottomNavOptions: {
          group: {
            pull: true,
            put: true
          },
          sort: true,
          animation: 100
        },
        editForm: false,
        editIdx: -1,
        editChildIdx: -1,
        rules: {
          title: [
            { required: true, message: '请输入菜单名称', trigger: 'blur' }
          ],
          menu_type: [
            { required: true, message: '请选择内容', trigger: 'blur' }
          ],
          'content.keywords': [
            { required: true, message: '请输入关键词', trigger: 'blur' }
          ],
          'content.url': [
            { required: true, message: '请输入网址', trigger: 'blur' }
          ],
          'content.appid': [
            { required: true, message: '请输入小程序appid', trigger: 'blur' }
          ],
          'content.path': [
            { required: true, message: '请输入小程序路径', trigger: 'blur' }
          ],
          'content.website': [
            { required: true, message: '请输入备用网址', trigger: 'blur' }
          ],
        }
      };
    },
    components: {
      draggable,
      selectLink
    },
    created() {
      this.getInfo()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      initActive() {
        this.editForm = false
        this.editIdx = -1
        this.editChildIdx = -1
      },
      setNavFunc(data) {
        this.editForm.content.nav_link = data
      },
      addItem() {
        if(this.data.length>2) {
          this.fail('一级菜单最多只能三个')
          return false
        }
        this.data.push({
          id: 0,
          title: '',
          menu_type: '',
          content: {
            keywords: '',
            url: '',
            appid: '',
            path: '',
            website: '',
            nav_link: {
              id: 0,
              title: '',
              path: ''
            }
          },
          children: []
        })
      },
      delItem(index) {
        this.data.splice(index,1)
        this.initActive()
      },
      editItem(index) {
        this.editForm = JSON.parse(JSON.stringify(this.data[index]))
        this.editIdx = index
        this.editChildIdx = -1
      },
      editChild(event,index,cindex) {
        this.editIdx = index
        this.editChildIdx = cindex
        this.editForm = JSON.parse(JSON.stringify(this.data[index].children[cindex]))
        event.stopPropagation()
        event.preventDefault()
      },
      addChild(event,index) {
        if(this.data[index].children.length>4) {
          this.fail('最多只能添加5个子菜单！')
          return false
        }
        this.data[index].children.push({
          id: 0,
          title: '',
          menu_type: '',
          content: {
            keywords: '',
            url: '',
            appid: '',
            path: '',
            website: '',
            nav_link: {
              id: 0,
              title: '',
              path: ''
            }
          },
        })
        event.stopPropagation();
        event.preventDefault();
      },
      delChild(index,cindex) {
        this.data[index].children.splice(cindex,1)
        this.initActive()
      },
      delNow() {
        if(this.editChildIdx===-1) {
          this.delItem(this.editIdx)
        } else {
          this.delChild(this.editIdx,this.editChildIdx)
        }
      },
      getInfo() {
        this.$api.channel.weixinMenu( {},res => {
          if(res.errcode == 0) {
            this.data = res.data
          }
        })
      },
      saveMenu(isSubmit) {
        var isOk = true
        if(this.data.length>0) {
          this.data.forEach(item=>{
            if(!item.title) {
              isOk = false
            }
            if(item.children && item.children.length>0) {
              item.children.forEach(child=>{
                if(!child.title) {
                  isOk = false
                }
              })
            }
          })
        }
        if(!isOk) {
          this.fail('请完善菜单内容')
          return false
        }
        this.showLoading()
        this.$api.channel.weixinMenuSave( {data: this.data, isSubmit: isSubmit},res => {
          this.hideLoading()
          if(res.errcode == 0) {
            this.success(res.errmsg)
          } else {
            this.fail(res.errmsg)
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(that.editChildIdx===-1) {
              that.data[that.editIdx] = that.editForm
            } else {
              that.data[that.editIdx].children[that.editChildIdx] = that.editForm
            }
            that.$forceUpdate()
            that.success('子菜单保存成功')
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style lang="less" scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .tree {
    color: #666;
    line-height: 1.8;
    padding-right: 30px;
    box-sizing: border-box;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 4px 0;
        div {
          font-size: 14px;
        }
        &:hover {
          background-color: #F5F7FA;
        }
        &.active {
          background-color: #F5F7FA;
        }
        .opt {
          font-size: 14px;
          i {
            margin-right: 10px;
          }
        }
      }
      .li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2px 0 2px 40px;
        font-size: 13px;
        cursor: pointer;
        &:hover {
          background-color: #F5F7FA;
        }
        &.active {
          background-color: #F5F7FA;
        }
        .opt {
          font-size: 14px;
          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .form {
    padding-left: 40px;
    box-sizing: border-box;
    //border-left: 1px dotted #999;
  }
</style>
